<template>
  <div>
    <div class="container" style="margin-bottom: 1rem">
      <el-row>
        <content-title :nav="nav"></content-title>
      </el-row>
      <el-row>
        <div class="sec-title">学生详情</div>
        <el-col :span="3">
          <el-image
            style="width: auto; height: 10rem"
            :src="stuInfo.cover"
          ></el-image>
        </el-col>
        <el-col :span="5" class="details">
          <div>姓名: {{ stuInfo.nick_name }}</div>
          <div>年龄: {{ stuInfo.age }}</div>
          <div>性别: {{ stuInfo.sex == 1 ? "男" : "女" }}</div>
        </el-col>
        <el-col :span="5" class="details">
          <div>联系电话: {{ stuInfo.mobile }}</div>
          <div>科目: {{ stuInfo.categories_child }}</div>
          <div>
            所属{{
              this.$route.query.type == "mec"
                ? "机构"
                : this.$route.query.type == "sch"
                ? "学校"
                : "教育局"
            }}:{{ $route.query.stuMec }}
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stuInfo: {},
      mechanism_name_list: [],
      categories_list: [],
      loading: false,
      form: {
        name: "",
        cover: "",
        master_age: null,
        sex: null,
        mobile: "",
        introduce: "",
        categories: "",
        mechanism_id: "",
      },
    };
  },
  computed: {
    nav() {
      // console.log(this.$route.query.type);
      if (this.$route.query.type == "sch") {
        return {
          firstNav: "智慧校园中心",
          secondNav: "学生详情",
        };
      } else if (this.$route.query.type == "mec") {
        return {
          firstNav: "校外监管中心",
          secondNav: "学生详情",
        };
      } else if (this.$route.query.type == "edu") {
        return {
          firstNav: "教育局管理中心",
          secondNav: "学生详情",
        };
      }
    },
  },
  methods: {
    getStuDetails() {
      let url = "/user/userInfo/findById";
      this.$axios
        .get(url, {
          params: {
            id: this.$route.query.stuID,
          },
        })
        .then((res) => {
          this.stuInfo = res.data.data;
          // console.log(this.stuInfo);
        });
    },
  },
  created() {
    this.getStuDetails();
  },
};
</script>

<style>
.details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 10rem;
  font-size: 1.5rem;
}
.alter .el-row > span {
  width: 7rem;
  display: inline-block;
  margin-right: 2rem;
  text-align: right;
  font-size: 1.5rem;
  color: #636567;
}
.alter .el-row {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
/* .alter .el-row .el-input {
  width: 60% !important;
} */
/* .avatar-uploader {
  display: inline-block;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
} */
</style>